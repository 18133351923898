export const detectBrowserLanguage = () => {
  const browserLang = navigator.language || navigator.userLanguage;
  
  // Extract the language code (e.g., 'en' from 'en-US')
  const languageCode = browserLang.split('-')[0];
  
  // List of supported languages in your app
  const supportedLanguages = ['en', 'es', 'pt'];
  
  // Check if the detected language is supported, otherwise default to 'en'
  return supportedLanguages.includes(languageCode) ? languageCode : 'en';
};