import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import BeatMappingCMS from './BeatMappingCMS';
import RhythmGame from './RhythmGame';
import { AuthProvider, useAuth } from './AuthContext';
import Home from './Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './i18n';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) return <Navigate to="/login" replace />;
  return children;
};

// Admin Route Component
const AdminRoute = ({ children }) => {
  const { user, isAdmin } = useAuth();
  
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!isAdmin) {
    return (
      <div className='no-access'>
        <h2>Looks like you are lost, go back to the dashboard</h2>
        <Link to="/dashboard" className="no-access-button">Go to dashboard</Link>
      </div>
    );
  }

  return children;
};

function AppContent() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const storedLang = localStorage.getItem('userLanguage');
    if (storedLang) {
      i18n.changeLanguage(storedLang);
    }
  }, [i18n]);

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            
            {/* Protected routes */}
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <RhythmGame />
                </ProtectedRoute>
              } 
            />
            
            {/* Admin routes */}
            <Route 
              path="/cms" 
              element={
                <AdminRoute>
                  <BeatMappingCMS />
                </AdminRoute>
              } 
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <AppContent />
    </I18nextProvider>
  );
}

export default App;