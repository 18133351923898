import { supabase } from './supabaseClient'

export const fetchBeatMaps = async () => {
  const { data, error } = await supabase
    .from('beat_maps')
    .select('*')
    .order('artist', { ascending: true });
  if (error) throw error
  return data.map(item => ({
    ...item,
    youtubeId: item.youtube_id,
    beatMap: item.beat_map
  }))
}

export const createBeatMap = async (beatMap) => {
  console.log('Creating beat map with data:', beatMap);

  // Validate required fields
  const requiredFields = ['title', 'artist', 'category', 'youtubeId', 'bpm', 'duration', 'beatMap'];
  const missingFields = requiredFields.filter(field => !beatMap[field]);
  if (missingFields.length > 0) {
    throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
  }

  const { data, error } = await supabase
    .from('beat_maps')
    .insert([{
      title: beatMap.title,
      artist: beatMap.artist,
      category: beatMap.category,
      youtube_id: beatMap.youtubeId,
      bpm: beatMap.bpm,
      duration: beatMap.duration,
      beat_map: beatMap.beatMap,
      sections: beatMap.sections || []
    }])
    .select()

  if (error) {
    console.error('Error creating beat map:', error);
    throw error;
  }

  if (!data || data.length === 0) {
    console.error('No data returned after creating beat map');
    throw new Error('Beat map was created, but no data was returned');
  }

  console.log('Created beat map:', data[0]);
  return data[0];
}

export const fetchBeatMap = async (id) => {
  const { data, error } = await supabase
    .from('beat_maps')
    .select('*')
    .eq('id', id)
    .single()
  if (error) throw error
  return data
}

export const updateBeatMap = async (id, updates) => {
  console.log('Updating beat map with id:', id, 'and data:', updates);

  const { data, error } = await supabase
    .from('beat_maps')
    .update({
      title: updates.title,
      artist: updates.artist,
      category: updates.category,
      youtube_id: updates.youtubeId,
      bpm: updates.bpm,
      duration: updates.duration,
      beat_map: updates.beatMap,
      sections: updates.sections || []
    })
    .eq('id', id)
    .select()

  if (error) {
    console.error('Error updating beat map:', error);
    throw error;
  }

  if (!data || data.length === 0) {
    console.error('No data returned after updating beat map');
    throw new Error('Beat map was updated, but no data was returned');
  }

  console.log('Updated beat map:', data[0]);
  return data[0];
}

export const deleteBeatMap = async (id) => {
  console.log('Deleting beat map with id:', id);

  const { error } = await supabase
    .from('beat_maps')
    .delete()
    .eq('id', id)

  if (error) {
    console.error('Error deleting beat map:', error);
    throw error;
  }

  console.log('Beat map deleted successfully');
}