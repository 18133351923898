import React, { useState, useEffect, useRef, useCallback } from 'react';
import YouTube from 'react-youtube';
import './BeatMappingCMS.css';
import { fetchBeatMaps, createBeatMap, updateBeatMap, deleteBeatMap } from './supabaseApi';
import { useAuth } from './AuthContext';

const BeatMappingCMS = () => {
  const { user, isAdmin, signOut } = useAuth();
  const [beatMaps, setBeatMaps] = useState([]);
  const [currentMap, setCurrentMap] = useState({
    id: null,
    title: '',
    artist: '',
    category: '',
    youtubeId: '',
    bpm: 0,
    duration: 0,
    beatMap: []
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [editableTime, setEditableTime] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');
  const [sortBy, setSortBy] = useState('dateAdded');
  const [sortOrder, setSortOrder] = useState('desc');
  const playerRef = useRef(null);
  const canvasRef = useRef(null);
  const [currentBeat, setCurrentBeat] = useState(0);
  const [isAutoGenerating, setIsAutoGenerating] = useState(false);
  const audioContextRef = useRef(null);
  const [editingBeatIndex, setEditingBeatIndex] = useState(null);

  useEffect(() => {
    fetchAllBeatMaps();
  }, []);

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      drawBeats();
    }
  }, [currentMap.beatMap, currentTime]);

  useEffect(() => {
    setEditableTime(currentTime);
  }, [currentTime]);

  const fetchAllBeatMaps = async () => {
    try {
      const data = await fetchBeatMaps();
      setBeatMaps(data);
    } catch (error) {
      console.error('Error fetching beat maps:', error);
    }
  };

  const handleInputChange = (e) => {
    setCurrentMap({ ...currentMap, [e.target.name]: e.target.value });
  };

  const generateBeats = (firstBeatTime, bpm, duration) => {
    const beatsPerSecond = bpm / 60;
    const secondsPerBeat = 1 / beatsPerSecond;
    const totalBeats = Math.floor(duration / secondsPerBeat);

    let generatedBeats = [];
    for (let i = 0; i < totalBeats; i++) {
      const time = firstBeatTime + i * secondsPerBeat;
      if (time < duration) {
        generatedBeats.push({ time, beat: i % 8 + 1 });
      }
    }

    return generatedBeats;
  };

  const handleAutoGenerate = () => {
    if (currentMap.beatMap.length === 0) {
      alert("Please add the first beat manually before auto-generating.");
      return;
    }

    const firstBeat = currentMap.beatMap[0];
    const generatedBeats = generateBeats(firstBeat.time, currentMap.bpm, currentMap.duration);

    setCurrentMap(prevMap => ({
      ...prevMap,
      beatMap: generatedBeats
    }));

    setIsAutoGenerating(true);
  };

  const handleNewOneCount = () => {
    if (editableTime === 0 || currentMap.beatMap.length === 0) {
      alert("Please add some beats and move the playhead before setting a new one count.");
      return;
    }

    const newOneCount = editableTime;
    const newBeatMap = currentMap.beatMap.filter(beat => beat.time <= newOneCount);
    
    // Generate new beats after the new one count
    const remainingDuration = currentMap.duration - newOneCount;
    const beatsPerSecond = currentMap.bpm / 60;
    const secondsPerBeat = 1 / beatsPerSecond;
    const newBeats = Math.floor(remainingDuration / secondsPerBeat);

    for (let i = 0; i < newBeats; i++) {
      const time = newOneCount + i * secondsPerBeat;
      if (time < currentMap.duration) {
        newBeatMap.push({ time, beat: (i % 8) + 1 });
      }
    }

    setCurrentMap(prevMap => ({
      ...prevMap,
      beatMap: newBeatMap.sort((a, b) => a.time - b.time)
    }));

    alert(`New "one" count set at ${newOneCount.toFixed(2)} seconds. Beats after this point have been regenerated.`);
  };

  const handleBeatTimeEdit = (index, newTime) => {
    const updatedBeatMap = [...currentMap.beatMap];
    updatedBeatMap[index] = { ...updatedBeatMap[index], time: parseFloat(newTime) };
    
    updatedBeatMap.sort((a, b) => a.time - b.time);
  
    setCurrentMap(prevMap => ({
      ...prevMap,
      beatMap: updatedBeatMap
    }));
    setEditingBeatIndex(null);
  };

  const playBeep = useCallback(() => {
    if (!audioContextRef.current) return;

    const oscillator = audioContextRef.current.createOscillator();
    const gainNode = audioContextRef.current.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioContextRef.current.destination);

    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(880, audioContextRef.current.currentTime);

    gainNode.gain.setValueAtTime(0, audioContextRef.current.currentTime);
    gainNode.gain.linearRampToValueAtTime(1, audioContextRef.current.currentTime + 0.01);
    gainNode.gain.linearRampToValueAtTime(0, audioContextRef.current.currentTime + 0.1);

    oscillator.start();
    oscillator.stop(audioContextRef.current.currentTime + 0.1);
  }, []);

  const handleBeatAdd = useCallback(() => {
    if (isAutoGenerating) {
      setIsAutoGenerating(false);
    }
    setCurrentMap(prevMap => ({
      ...prevMap,
      beatMap: [...prevMap.beatMap, { time: currentTime, beat: prevMap.beatMap.length % 8 + 1 }]
        .sort((a, b) => a.time - b.time)
    }));
    playBeep();
  }, [currentTime, isAutoGenerating, playBeep]);

  const handleBeatDelete = (indexToDelete) => {
    setCurrentMap({
      ...currentMap,
      beatMap: currentMap.beatMap.filter((_, index) => index !== indexToDelete)
        .map((beat, index) => ({ ...beat, beat: index % 8 + 1 }))
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const submissionData = {
        ...currentMap,
        youtubeId: currentMap.youtubeId,
        beatMap: currentMap.beatMap
      };
      console.log('Submitting data:', submissionData);
  
      let response;
      if (isEditing) {
        response = await updateBeatMap(currentMap.id, submissionData);
        console.log('Updated beat map:', response);
        alert('Beat map updated successfully!');
      } else {
        response = await createBeatMap(submissionData);
        console.log('Created new beat map:', response);
        alert('New beat map saved successfully!');
      }
  
      fetchAllBeatMaps();
      setIsModalOpen(false);
      resetForm();
    } catch (error) {
      console.error('Error saving beat map:', error);
      alert(`Error saving beat map: ${error.message}`);
    }
  };  

  const handleDeleteMap = async (id) => {
    if (window.confirm('Are you sure you want to delete this beat map?')) {
      try {
        await deleteBeatMap(id);
        fetchAllBeatMaps();
        if (currentMap.id === id) {
          resetForm();
        }
      } catch (error) {
        console.error('Error deleting beat map:', error);
      }
    }
  };

  const handleEditMap = (map) => {
    setCurrentMap(map);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const resetForm = () => {
    setCurrentMap({
      id: null,
      title: '',
      artist: '',
      category: '',
      youtubeId: '',
      bpm: 0,
      duration: 0,
      beatMap: []
    });
    setIsEditing(false);
    if (playerRef.current) {
      playerRef.current.stopVideo();
    }
  };

  const onReady = (event) => {
    playerRef.current = event.target;
    setCurrentMap(prev => ({ ...prev, duration: event.target.getDuration() }));
  };

  const onStateChange = (event) => {
    setIsPlaying(event.data === YouTube.PlayerState.PLAYING);
  };

  const onPlayPauseClick = (e) => {
    e.preventDefault();
    if (isPlaying) {
      playerRef.current.pauseVideo();
    } else {
      playerRef.current.playVideo();
    }
  };

  const drawBeats = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = '#2a2a2a';
    ctx.fillRect(0, 0, width, height);

    const duration = currentMap.duration;
    let lastOneCount = 0;

    currentMap.beatMap.forEach((beat, index) => {
      const x = (beat.time / duration) * width;
      
      if (beat.beat === 1 && beat.time > lastOneCount) {
        lastOneCount = beat.time;
        ctx.fillStyle = '#ff0000'; // Red for new "one" count
        ctx.fillRect(x - 3, 0, 6, height);
      } else {
        ctx.fillStyle = beat.time <= currentTime ? '#8007b9' : '#a12560';
        ctx.fillRect(x - 2, 0, 4, height);
      }

      // Draw beat number
      ctx.fillStyle = '#ffffff';
      ctx.font = '10px Arial';
      ctx.fillText(beat.beat.toString(), x - 3, height - 5);
    });

    // Draw playhead
    const playheadX = (currentTime / duration) * width;
    ctx.fillStyle = '#fff';
    ctx.fillRect(playheadX - 1, 0, 2, height);
  };

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        const newTime = playerRef.current.getCurrentTime();
        setCurrentTime(newTime);
        setEditableTime(newTime);
        const nearestBeat = currentMap.beatMap.find(beat => 
          Math.abs(beat.time - newTime) < 0.05
        );
        if (nearestBeat) {
          playBeep();
          setCurrentBeat(nearestBeat.beat);
        }
      }, 50);
    }
    return () => clearInterval(interval);
  }, [isPlaying, currentMap.beatMap, playBeep]);

  const BeatCounter = () => {
    const beats = [1, 2, 3, 4, 5, 6, 7, 8];
    return (
      <div className="beat-counter">
        {beats.map(beat => (
          <div
            key={beat}
            className={`beat ${currentBeat === beat ? 'active' : ''}`}
          >
            {beat}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === 'Space' && isPlaying) {
        event.preventDefault();
        handleBeatAdd();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isPlaying, handleBeatAdd]);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  const handleSortChange = (sortType) => {
    if (sortType === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(sortType);
      setSortOrder('desc');
    }
  };

  const getFilteredAndSortedSongs = () => {
    let filtered = beatMaps.filter(song => 
      (song.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
       song.artist.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (categoryFilter === '' || song.category === categoryFilter)
    );
    
    if (activeFilter !== 'all') {
      filtered = filtered.filter(song => {
        if (activeFilter === 'Easy') return song.bpm < 90;
        if (activeFilter === 'Medium') return song.bpm >= 90 && song.bpm <= 120;
        if (activeFilter === 'Hard') return song.bpm > 120;
        return true;
      });
    }
    
    return filtered.sort((a, b) => {
      if (sortBy === 'dateAdded') {
        return sortOrder === 'asc' ? a.dateAdded - b.dateAdded : b.dateAdded - a.dateAdded;
      } else if (sortBy === 'title') {
        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
      }
      // Add more sort options as needed
    });
  };

  const getDifficultyColor = (bpm) => {
    if (bpm < 90) return 'text-green-500';
    if (bpm <= 120) return 'text-yellow-500';
    return 'text-red-500';
  };

  const getDifficulty = (bpm) => {
    if (bpm < 90) return 'Easy';
    if (bpm <= 120) return 'Medium';
    return 'Hard';
  };

  return (
    <div className="beat-mapping-cms">
      <div className="cms-header">
        <div className='cms-logo'>
          <img src='/azucar-flow-logo.png' alt="Azucar Flow Logo" />
        </div>
        <div className=''>
          <button onClick={signOut} className='sign-out-btn-cms'>Sign Out</button>
          <button className="add-song-button" onClick={() => { setIsModalOpen(true); setIsEditing(false); }}>
            Add New Song
          </button>
        </div>
      </div>

      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by artist or song name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      <div className="filters">
        <select 
          value={categoryFilter} 
          onChange={(e) => setCategoryFilter(e.target.value)}
          className="category-select"
        >
          <option value="">All Categories</option>
          <option value="Salsa">Salsa</option>
          <option value="Bachata">Bachata</option>
        </select>
        <button 
          className={`filter-button ${activeFilter === 'all' ? 'active' : ''}`}
          onClick={() => handleFilterChange('all')}
        >
          All
        </button>
        <button 
          className={`filter-button ${activeFilter === 'Easy' ? 'active' : ''}`}
          onClick={() => handleFilterChange('Easy')}
        >
          Slow Speed
        </button>
        <button 
          className={`filter-button ${activeFilter === 'Medium' ? 'active' : ''}`}
          onClick={() => handleFilterChange('Medium')}
        >
          Medium Speed
        </button>
        <button 
          className={`filter-button ${activeFilter === 'Hard' ? 'active' : ''}`}
          onClick={() => handleFilterChange('Hard')}
        >
          Fast Speed
        </button>
      </div>

      <table className="song-table">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => handleSortChange('title')}>Title {sortBy === 'title' && (sortOrder === 'asc' ? '▲' : '▼')}</th>
            <th>Artist</th>
            <th>BPM</th>
            <th>Difficulty</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {getFilteredAndSortedSongs().map((song, index) => (
            <tr key={song.id}>
              <td>{index + 1}</td>
              <td>{song.title}</td>
              <td>{song.artist}</td>
              <td>{song.bpm}</td>
              <td className={getDifficultyColor(song.bpm)}>{getDifficulty(song.bpm)}</td>
              <td>
                <button className="edit-button" onClick={() => handleEditMap(song)}>Edit</button>
                <button className="delete-button" onClick={() => handleDeleteMap(song.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>{isEditing ? 'Edit Beat Map' : 'Add New Beat Map'}</h2>
            <form onSubmit={handleSubmit}>
              <div className='form-input-row'>
                <input
                  type="text"
                  name="title"
                  value={currentMap.title}
                  onChange={handleInputChange}
                  placeholder="Song Title"
                />
                <input
                  type="text"
                  name="artist"
                  value={currentMap.artist}
                  onChange={handleInputChange}
                  placeholder="Artist"
                />
                <select
                  name="category"
                  value={currentMap.category}
                  onChange={handleInputChange}
                >
                  <option value="">Select Category</option>
                  <option value="Salsa">Salsa</option>
                  <option value="Bachata">Bachata</option>
                </select>
                <input
                  type="text"
                  name="youtubeId"
                  value={currentMap.youtubeId}
                  onChange={handleInputChange}
                  placeholder="YouTube ID"
                />
                <input
                  type="number"
                  name="bpm"
                  value={currentMap.bpm}
                  onChange={handleInputChange}
                  placeholder="BPM"
                />
              </div>
              

              {currentMap.youtubeId && (
                <div className="youtube-container">
                  <YouTube
                    videoId={currentMap.youtubeId}
                    opts={{ height: '195', width: '320', playerVars: { controls: 1 } }}
                    onReady={onReady}
                    onStateChange={onStateChange}
                  />
                  <div className="video-controls">
                    <button type="button" onClick={onPlayPauseClick}>{isPlaying ? 'Pause' : 'Play'}</button>
                    <button type="button" onClick={handleBeatAdd}>Add Beat</button>
                    <button type="button" onClick={handleAutoGenerate} disabled={isAutoGenerating}>
                      {isAutoGenerating ? 'Auto-generating...' : 'Auto-generate Beats'}
                    </button>
                    <button type="button" onClick={handleNewOneCount}>Set New "One" Count</button>
                  </div>
                  <BeatCounter />
                  <canvas ref={canvasRef} width={320} height={50} className="beat-canvas" />
                  <div className="time-control">
                    <input
                      type="number"
                      value={editableTime.toFixed(2)}
                      onChange={(e) => {
                        const newTime = parseFloat(e.target.value);
                        setEditableTime(newTime);
                      }}
                      onBlur={() => {
                        if (playerRef.current) {
                          playerRef.current.seekTo(editableTime);
                          setCurrentTime(editableTime);
                        }
                      }}
                      step="0.01"
                      min="0"
                      max={currentMap.duration}
                    />
                    <span>/ {currentMap.duration.toFixed(2)}</span>
                  </div>
                  <p>Press SPACEBAR to add beats while playing</p>
                </div>
              )}

              <div className="beat-list">
                <h3>Beat Map</h3>
                {currentMap.beatMap.map((beat, index) => (
                  <div key={index} className="beat-item">
                    {editingBeatIndex === index ? (
                      <input
                        type="number"
                        step="0.01"
                        value={beat.time.toFixed(2)}
                        onChange={(e) => handleBeatTimeEdit(index, e.target.value)}
                        onBlur={() => setEditingBeatIndex(null)}
                        autoFocus
                      />
                    ) : (
                      <span onClick={() => setEditingBeatIndex(index)}>
                        Time: {beat.time.toFixed(2)}, Beat: {beat.beat}
                      </span>
                    )}
                    <button onClick={() => handleBeatDelete(index)}>Delete</button>
                  </div>
                ))}
              </div>

              <button type="submit" className='submit-btn'>{isEditing ? 'Update' : 'Save'} Beat Map</button>
            </form>
            <button type="button" onClick={() => setIsModalOpen(false)} className="close-modal">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BeatMappingCMS;