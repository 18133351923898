import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Check } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';
import './Home.css'; 
import LanguageSelector from './languageSelector';

const Home = () => {
    const { t } = useTranslation();
    const { user, subscription } = useAuth();
    const [openIndex, setOpenIndex] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const textAreaRef = useRef(null);

    const faqData = [
      {
        question: t('faq.question1'),
        answer: t('faq.answer1')
      },
      {
        question: t('faq.question2'),
        answer: t('faq.answer2')
      },
      {
        question: t('faq.question3'),
        answer: t('faq.answer3')
      },
      {
        question: t('faq.question4'),
        answer: t('faq.answer4')
      },
      {
        question: t('faq.question5'),
        answer: t('faq.answer5')
      },
      {
        question: t('faq.question6'),
        answer: t('faq.answer6'),
        hasCopyLink: true
      }
    ];
    
    const [isAnnual, setIsAnnual] = useState(false);

    const getPricingButtonText = (isPremiumCard) => {
        if (!user) {
            return isPremiumCard ? t('pricing.cardButtonPremium') : t('pricing.cardButtonFree');
        }
        
        if (subscription === 'premium') {
            return isPremiumCard ? t('pricing.goToDashboard') : t('pricing.downgrade');
        }
        
        return isPremiumCard ? t('pricing.upgrade') : t('pricing.goToDashboard');
    };

    const getCtaButtonText = () => {
        return user ? t('common.goToDashboard') : t('hero.ctaButton');
    };


    const getPricingButtonLink = (isPremiumCard) => {
        if (!user) return '/signup';
        if (subscription === 'premium') {
            return isPremiumCard ? '/dashboard' : '/downgrade';
        }
        return isPremiumCard ? '/upgrade' : '/dashboard';
    };

    const toggleFAQ = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };


    const copyToClipboard = () => {
        textAreaRef.current.select();
        document.execCommand('copy');
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };

    const renderNavButtons = () => {
        if (user) {
            return (
                <Link to="/dashboard" className="nav-button">
                    {t('common.dashboard')}
                </Link>
            );
        }
        return (
            <>
                <Link to="/login" className="nav-link">{t('login')}</Link>
                <Link to="/signup" className="nav-button">{t('signUp')}</Link>
            </>
        );
    };


    return (
        <div className="home">
            <header>
                <nav>
                    <div className='nav-bar'>
                        <div className='nav-bar-inner'>
                            <div className="logo">
                                <img src='/azucar-flow-logo.png' alt={t('logoAlt')} className='logo-img' />
                            </div>
                            <div className='nav-actions'>
                                <LanguageSelector />
                                {renderNavButtons()}
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <main>
                <section className='hero'>
                    <div className='wrapper'>
                        <div className='hero-content-wrapper'>
                            <h1>{t('hero.title')}</h1>
                            <p>{t('hero.description')}</p>
                            <Link to={user ? "/dashboard" : "/signup"} className="cta-button">
                                {getCtaButtonText()}
                            </Link>
                        </div>
                        <div className='hero-img-wrapper'>
                            <img src='/azucar-flow-hero.gif' alt={t('hero.imageAlt')} className='hero-img' />
                        </div>
                    </div>
                </section>

                <section className='problem'>
                    <div className='wrapper'>
                        <div className='problem-container'>
                            <div className='problem-content'>
                                <h2 className='section-title'>{t('problem.title')}</h2>
                                <p>
                                    {t('problem.description1')}
                                    <br /><br />
                                    {t('problem.description2')}
                                    <br /><br />
                                    {t('problem.description3')}
                                    <br /><br />
                                    {t('problem.description4')}
                                </p>
                            </div>
                            <div className='problem-pains'>
                                <div className='pain'>{t('problem.pain1')}</div>
                                <div className='pain'>{t('problem.pain2')}</div>
                                <div className='pain'>{t('problem.pain3')}</div>
                                <div className='pain'>{t('problem.pain4')}</div>
                                <div className='pain'>{t('problem.pain5')}</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='benefits' id='benefits'>
                    <div className='wrapper'>
                        <div className='section-headline-wrapper'>
                            <h2 className='section-title'>{t('benefits.title')}</h2>
                            <p className='section-p'>
                                {t('benefits.description1')}
                                <br /><br />
                                {t('benefits.description2')}
                            </p>
                        </div>
                        <div className='benefits-container'>
                            {['benefit1', 'benefit2', 'benefit3', 'benefit4'].map((benefit, index) => (
                                <div key={index} className='benefits-item'>
                                    <div className={`benefits-image-wrapper benefit-${index + 1}`}>
                                        <img src={`/landing-page/${t(`benefits.${benefit}.image`)}`} alt={t(`benefits.${benefit}.imageAlt`)} />
                                    </div>
                                    <div className='benefits-content'>
                                        <h3>{t(`benefits.${benefit}.title`)}</h3>
                                        <p>{t(`benefits.${benefit}.description`)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className='testimonials'>
                <div className='wrapper'>
                    <div className='section-headline-wrapper'>
                    <h2 className='section-title'>{t('testimonials.title')}</h2>
                    <p className='section-p'>{t('testimonials.description')}</p>
                    </div>
                    <div className='testimonials-grid'>
                    {[1, 2, 3, 4, 5, 6].map((index) => (
                        <div key={index} className='testimonial-card'>
                        <div className='testimonial-content'>
                            <p className='testimonial-text'>
                            {t(`testimonials.testimonial${index}.text`)}
                            </p>
                            <div className='testimonial-author'>
                            <img 
                                src={`/testimonials/avatar${index}.jpg`} 
                                alt={t(`testimonials.testimonial${index}.name`)}
                                className='testimonial-avatar'
                            />
                            <div className='testimonial-info'>
                                <h4>{t(`testimonials.testimonial${index}.name`)}</h4>
                                <p>{t(`testimonials.testimonial${index}.title`)}</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                </div>
                </section>

                <section className='pricing'>
                <div className='wrapper'>
                    <div className='pricing-container'>
                    <div className='section-headline-wrapper'>
                        <h2 className='section-title'>{t('pricing.title')}</h2>
                        <p className='section-p'>{t('pricing.description1')}</p>
                    </div>

                    {/* Pricing Toggle */}
                    <div className="pricing-toggle">
                        <button
                            type="button"
                            onClick={() => setIsAnnual(false)}
                            className={`toggle-button ${!isAnnual ? 'active' : ''}`}
                        >
                            {t('pricing.toggleMonthly')}
                        </button>
                        <button
                            type="button"
                            onClick={() => setIsAnnual(true)}
                            className={`toggle-button ${isAnnual ? 'active' : ''}`}
                        >
                            {t('pricing.toggleYearly')} <span className="save-text">-25%</span>
                        </button>
                        </div>

                        {/* Pricing Cards */}
                        <div className="pricing-cards">
                        {/* Free Tier */}
                        <div className="pricing-card free">
                            <div className="card-header">
                            <h3 className="card-title">{t('pricing.cardTitleFree')}</h3>
                            <p className="card-description">{t('pricing.cardDescriptionFree')}</p>
                            <p className="price">
                                <span className="amount">$0</span>
                                <span className="period">/{t('pricing.month')}</span>
                            </p>
                            <div className='pricing-button-wrapper'>
                                <Link 
                                    to={getPricingButtonLink(false)} 
                                    className="card-button button-free"
                                >
                                    {getPricingButtonText(false)}
                                </Link>
                            </div>
                            </div>
                            <div className="card-features">
                            <h4 className="features-title">{t('pricing.cardFeatureTitleFree')}</h4>
                            <ul className="features-list">
                                <li className="feature-item">
                                    <Check className="check-icon" />
                                    <span>{t('pricing.cardFeatureOne')}</span>
                                </li>

                                <li className="feature-item">
                                    <Check className="check-icon" />
                                    <span>{t('pricing.cardFeatureTwo')}</span>
                                </li>

                                <li className="feature-item">
                                    <Check className="check-icon" />
                                    <span>{t('pricing.cardFeatureThree')}</span>
                                </li>

                                <li className="feature-item">
                                    <Check className="check-icon" />
                                    <span>{t('pricing.cardFeatureFour')}</span>
                                </li>
                            </ul>
                            </div>
                        </div>

                        {/* Premium Tier */}
                        <div className="pricing-card premium">
                            <div className="card-header">
                            <h3 className="card-title">{t('pricing.cardTitlePremium')}</h3>
                            <p className="card-description">{t('pricing.cardDescriptionPremium')}</p>
                            <p className="price">
                                <span className="amount">${isAnnual ? '0.75' : '0.99'}</span>
                                <span className="period">/{t('pricing.month')}</span>
                                {isAnnual && (
                                <span className="annual-note">{t('pricing.cardAnnualDescription')} (${9}/{t('pricing.year')})</span>
                                )}
                            </p>
                            <div className='pricing-button-wrapper'>
                                <Link 
                                    to={getPricingButtonLink(true)} 
                                    className="card-button button-premium"
                                >
                                    {getPricingButtonText(true)}
                                </Link>
                            </div>
                            </div>
                            <div className="card-features">
                            <h4 className="features-title">{t('pricing.cardFeatureTitlePremium')}</h4>
                            <ul className="features-list">
                                <li className="feature-item">
                                    <Check className="check-icon" />
                                    <span>{t('pricing.cardFeatureOnePremium')}</span>
                                </li>

                                <li className="feature-item">
                                    <Check className="check-icon" />
                                    <span>{t('pricing.cardFeatureTwoPremium')}</span>
                                </li>

                                <li className="feature-item">
                                    <Check className="check-icon" />
                                    <span>{t('pricing.cardFeatureThreePremium')}</span>
                                </li>

                                <li className="feature-item">
                                    <Check className="check-icon" />
                                    <span>{t('pricing.cardFeatureFourPremium')}</span>
                                </li>

                                <li className="feature-item">
                                    <Check className="check-icon" />
                                    <span>{t('pricing.cardFeatureFivePremium')}</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </section>

                <section className='faqs' id="faq">
                    <div className='wrapper'>
                        <div className='section-headline-wrapper'>
                            <h2 className='section-title'>{t('faq.title')}</h2>
                        </div>
                        <div className='faq-bg'>
                            <div className='faq-container'>
                                {faqData.map((faq, index) => (
                                    <div key={index} className={`faq-item ${openIndex === index ? 'active' : ''}`}>
                                        <div className='faq-question' onClick={() => toggleFAQ(index)}>
                                            <h3>{faq.question}</h3>
                                            <span className='faq-toggle'>{openIndex === index ? '+' : '+'}</span>
                                        </div>
                                        <div className='faq-answer'>
                                            <p>{faq.answer}</p>
                                            {faq.hasCopyLink && (
                                                <div className="copy-link-container">
                                                    <p>{t('faq.copyLinkText')}</p>
                                                    <div className="copy-link-input">
                                                        <input 
                                                            ref={textAreaRef}
                                                            value="https://azucarflow.com/"
                                                            readOnly
                                                        />
                                                        <button onClick={copyToClipboard}>
                                                            {copySuccess ? t('faq.linkCopiedText') : t('faq.copyButtonText')}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                <section className='cta'>
                    <div className='wrapper'>
                        <div className='cta-inner'>
                            <h2 className='section-title'>{t('cta.title')}</h2>
                            <p>{t('cta.description')}</p>
                            <Link 
                                to={user ? "/dashboard" : "/signup"} 
                                className="cta-button"
                            >
                                {getCtaButtonText()}
                            </Link>
                        </div>
                    </div>
                </section>

                <section className='footer'>
                    <div className='wrapper'>
                        <div className='footer-inner'>
                            <p>{t('footer.createdBy')} <Link to="https://www.priscilabarkmann.com/" className="create-link">Priscila Barkmann</Link> & <Link to="https://mtzangel.com/" className="create-link">Angel Martinez</Link></p>
                            <div className='footer-links'>
                                <Link to="/privacy-policy" className="footer-link">{t('footer.privacyPolicy')}</Link>
                                <Link to="/terms" className="footer-link">{t('footer.terms')}</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Home;