import React, { useState, useEffect, useRef, useCallback } from 'react';
import YouTube from 'react-youtube';
import './RhythmGame.css';
import { fetchBeatMaps } from './supabaseApi';
import { useAuth } from './AuthContext';
import AccountPopup from './AccountPopup';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './languageSelector';
import confetti from 'canvas-confetti';



const correctMessages = [
  "¡Excelente! Estás en ritmo.",
  "¡Buen trabajo! Sigue así.",
  "¡Tienes el sabor!",
  "¡Perfecto! Eres todo un salsero/salsera.",
  "¡Estás en fuego! Keep dancing.",
  "¡Así se baila! Keep the groove.",
  "¡Lo tienes! ¡Sigue adelante!",
  "¡Ritmo perfecto! Keep the flow.",
  "¡Vamos! ¡Eres una estrella de la pista!",
  "¡Bailas con el corazón! Keep it up.",
  "¡Qué swing! Keep going.",
  "¡Tu tumbao está en punto!",
  "¡Qué sabor! You're nailing it.",
  "¡Baila, baila! Keep the rhythm.",
  "¡Esa es la clave! Keep moving.",
  "¡Estás dando cátedra!",
  "¡Qué rumba! Keep grooving.",
  "¡Sigue el compás! You're doing great.",
  "¡Lo llevas en la sangre!",
  "¡Eres un maestro/maestra del ritmo!"
];


const RhythmGame = () => {
  const { user, signOut } = useAuth();
  const [gameState, setGameState] = useState('dashboard');
  const [category, setCategory] = useState('');
  const [songs, setSongs] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [countMode, setCountMode] = useState('1');
  const [searchTerm, setSearchTerm] = useState('');
  const [difficulty, setDifficulty] = useState('all');
  const [artists, setArtists] = useState([]);
  const [currentArtist, setCurrentArtist] = useState(null);
  const playerRef = useRef(null);
  const startTimeRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState('all');
  const [sortBy, setSortBy] = useState('dateAdded');
  const [sortOrder, setSortOrder] = useState('desc');
  const [activeTab, setActiveTab] = useState('visualize');
  const [streak, setStreak] = useState(0);
  const [mistakes, setMistakes] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [currentBeat, setCurrentBeat] = useState(null);
  const audioContextRef = useRef(null);
  const [beepVolume, setBeepVolume] = useState(0.5);
  const [isMistake, setIsMistake] = useState(false);
  const [showAccountPopup, setShowAccountPopup] = useState(false);
  const { t } = useTranslation();
  const [instructionsOpacity, setInstructionsOpacity] = useState(1);
  const mainContentRef = useRef(null);
  const gameContentRef = useRef(null);
  const [buttonAnimation, setButtonAnimation] = useState('');
  const [shouldPlayBeeps, setShouldPlayBeeps] = useState(true);
  const lastPlayedBeatRef = useRef(null);
  const [totalCorrect, setTotalCorrect] = useState(0);
  const [totalMistakes, setTotalMistakes] = useState(0);
  const [totalAttempts, setTotalAttempts] = useState(0);
  const [accuracy, setAccuracy] = useState(0);



  useEffect(() => {
  // Initialize AudioContext
  audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
  return () => {
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
  };
  }, []);


  const playBeep = useCallback((delay = 0, volume = 0.5, beatNumber = null) => {
    if (!audioContextRef.current || !shouldPlayBeeps) return;
  
    const oscillator = audioContextRef.current.createOscillator();
    const gainNode = audioContextRef.current.createGain();
  
    oscillator.connect(gainNode);
    gainNode.connect(audioContextRef.current.destination);
  
    // Shorter, more precise sound
    oscillator.type = beatNumber === 1 ? 'square' : 'sine';
    oscillator.frequency.setValueAtTime(
      beatNumber === 1 ? 1600 : 1200, 
      audioContextRef.current.currentTime
    );
  
    // Shorter envelope
    const startTime = audioContextRef.current.currentTime;
    gainNode.gain.setValueAtTime(0, startTime);
    gainNode.gain.linearRampToValueAtTime(volume, startTime + 0.01);
    gainNode.gain.linearRampToValueAtTime(0, startTime + 0.05); // Shorter duration
  
    oscillator.start(startTime);
    oscillator.stop(startTime + 0.05); // Stop after 50ms
  
    // Clean up sooner
    setTimeout(() => {
      try {
        gainNode.disconnect();
        oscillator.disconnect();
      } catch (e) {
        console.error('Error cleaning up audio nodes:', e);
      }
    }, 100);
  }, [shouldPlayBeeps]);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };
  
  const handleSortChange = (sortType) => {
    if (sortType === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(sortType);
      setSortOrder('desc');
    }
  };

  const scrollToBottom = () => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({
        top: mainContentRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };
  
  const getFilteredAndSortedSongs = () => {
    let filtered = filteredSongs;
    
    if (activeFilter !== 'all') {
      filtered = filtered.filter(song => song.difficulty === activeFilter);
    }
    
    return filtered.sort((a, b) => {
      if (sortBy === 'dateAdded') {
        return sortOrder === 'asc' ? a.dateAdded - b.dateAdded : b.dateAdded - a.dateAdded;
      } else if (sortBy === 'title') {
        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
      }
      // Add more sort options as needed
    });
  };

  const beatPatterns = {
    '1,2,3,4,5,6,7,8': [1, 2, 3, 4, 5, 6, 7, 8],
    '1': [1],
    '1,5': [1, 5],
    '1,3,5,7': [1, 3, 5, 7],
    '1,2,3,5,6,7': [1, 2, 3, 5, 6, 7],
    '2,6': [2, 6],
    '2,3,6,7': [2, 3, 6, 7],
    '4,8': [4, 8],
    '2,4,6,8': [2, 4, 6, 8],
    '2,3,4,6,7,8': [2, 3, 4, 6, 7, 8]
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBeatMaps();
        const sortedSongs = data.sort((a, b) => a.bpm - b.bpm);
        const processedSongs = sortedSongs.map(song => ({
          ...song,
          difficulty: song.bpm < 90 ? 'Easy' : song.bpm <= 120 ? 'Medium' : 'Hard'
        }));
        setSongs(processedSongs);
        const extractedArtists = extractArtists(processedSongs);
        setArtists(extractedArtists);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (category || searchTerm || difficulty !== 'all' || currentArtist) {
      filterSongs();
    }
  }, [category, songs, searchTerm, difficulty, currentArtist]);

  useEffect(() => {
    if (activeTab === 'visualize' && 
        isPlaying && 
        shouldPlayBeeps && 
        beatPatterns[countMode].includes(currentBeat) && 
        lastPlayedBeatRef.current !== currentBeat) { // Only play if it's a new beat
      
      lastPlayedBeatRef.current = currentBeat;
      playBeep(0, beepVolume, currentBeat);
    }
  }, [currentBeat, activeTab, isPlaying, shouldPlayBeeps, countMode, beatPatterns, playBeep, beepVolume]);

  const fetchAllSongs = async () => {
    try {
      const data = await fetchBeatMaps();
      const sortedSongs = data.sort((a, b) => a.bpm - b.bpm);
      setSongs(sortedSongs.map(song => ({
        ...song,
        difficulty: song.bpm < 90 ? 'Easy' : song.bpm <= 120 ? 'Medium' : 'Hard'
      })));
    } catch (error) {
      console.error('Error fetching songs:', error);
    }
  };

  useEffect(() => {
    if (isPlaying && currentSong) {
      const currentBeatIndex = currentSong.beatMap.findIndex(beat => beat.time > currentTime);
      const newBeat = currentBeatIndex > 0 ? currentSong.beatMap[currentBeatIndex - 1].beat : null;
      setCurrentBeat(newBeat);
    }
  }, [currentTime, currentSong, isPlaying]);


  /*
  const fetchArtists = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/artists`);
      setArtists(response.data);
    } catch (error) {
      console.error('Error fetching artists:', error);
    }
  };*/

  const getArtistImage = (artistName) => {
    // Normalize accents and special characters
    const normalizedName = artistName
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9]/g, '')
      .toLowerCase();
  
    return `/artists/${normalizedName}.jpg`;
  };

  const featuredArtists = [
    { name: "Gilberto Santa Rosa", genre: "Salsa", image: getArtistImage("Gilberto Santa Rosa") },
    { name: "Romeo Santos", genre: "Bachata", image: getArtistImage("Romeo Santos") },
    { name: "Marc Anthony", genre: "Salsa", image: getArtistImage("Marc Anthony") },
    { name: "Prince Royce", genre: "Bachata", image: getArtistImage("Prince Royce") },
    { name: "Willie Colón", genre: "Salsa", image: getArtistImage("Willie Colón") },
    { name: "Aventura", genre: "Bachata", image: getArtistImage("Aventura") },
  ];

  const filterSongs = () => {
    let filtered = songs;
    
    if (category) {
      filtered = filtered.filter(song => song.category === category);
    }
    
    if (searchTerm) {
      filtered = filtered.filter(song => 
        song.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        song.artist.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    if (difficulty !== 'all') {
      filtered = filtered.filter(song => song.difficulty === difficulty);
    }
    
    if (currentArtist) {
      filtered = filtered.filter(song => song.artist === currentArtist);
    }
    
    setFilteredSongs(filtered);
  };

  const handleCategorySelect = (selectedCategory) => {
    setCategory(selectedCategory);
    setGameState('songList');
  };

  const handleVideoStateChange = (event) => {
    if (event.data === YouTube.PlayerState.PLAYING) {
      lastPlayedBeatRef.current = null; // Reset last played beat
      setShouldPlayBeeps(true);
      setIsPlaying(true);
      if (!gameStarted) {
        startGame();
      }
      setInstructionsOpacity(0);
      setTimeout(() => {
        scrollToBottom();
      }, 300);
    } else if (event.data === YouTube.PlayerState.PAUSED || event.data === YouTube.PlayerState.ENDED) {
      lastPlayedBeatRef.current = null; // Reset last played beat
      setShouldPlayBeeps(false);
      setIsPlaying(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'test') {
      setTimeout(() => {
        if (gameContentRef.current) {
          gameContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }, 100);
    }
  };
  

  const handleSongSelect = (song) => {
    setShouldPlayBeeps(false); // Stop beeps from previous song
    setCurrentSong(song);
    setGameState('game');
    // Reset game-related states
    setStreak(0);
    setHighScore(0);
    setMistakes(0);
    setGameStarted(false);
    setFeedbackMessage('');
    setScore(0);
    setActiveTab('visualize');
    setInstructionsOpacity(1);
    setTotalCorrect(0);
    setTotalMistakes(0);
    setTotalAttempts(0);
    setAccuracy(0);
    // Enable beeps for new song after a short delay
    setTimeout(() => setShouldPlayBeeps(true), 100);
  };

  const handleBeat = () => {
    if (!isPlaying) return;
  
    const tolerance = 0.2;
    const currentBeatObj = currentSong.beatMap.find(beat => 
      Math.abs(beat.time - currentTime) < tolerance
    );
  
    setTotalAttempts(prev => prev + 1);
  
    if (currentBeatObj) {
      const isCorrectBeat = beatPatterns[countMode].includes(currentBeatObj.beat);
      
      if (isCorrectBeat) {
        setTotalCorrect(prev => prev + 1);
        setStreak(prevStreak => {
          const newStreak = prevStreak + 1;
          setHighScore(prevHighScore => Math.max(prevHighScore, newStreak));
          return newStreak;
        });
        const randomMessageIndex = Math.floor(Math.random() * 10) + 1;
        setFeedbackMessage(t(`game.correctMessages.${randomMessageIndex}`));
        setButtonAnimation('correct');
        showConfetti();
      } else {
        // New feedback logic for wrong beat
        const expectedBeats = countMode.split(',').join(', ');
        const feedbackMessage = t('game.wrongBeatMessage', {
          clickedBeat: currentBeatObj.beat,
          expectedBeats: expectedBeats
        });
        setFeedbackMessage(feedbackMessage);
        handleMistake();
        setTotalMistakes(prev => prev + 1);
      }
  
      setAccuracy(((totalCorrect + (isCorrectBeat ? 1 : 0)) / (totalAttempts + 1) * 100).toFixed(1));
    } else {
      // Feedback for clicking when there's no beat
      setFeedbackMessage(t('game.noBeatMessage', {
        expectedBeats: countMode.split(',').join(', ')
      }));
      handleMistake();
      setTotalMistakes(prev => prev + 1);
    }
  };
  
  const handleMistake = () => {
    setMistakes(prevMistakes => prevMistakes + 1);
    setStreak(0);
    setIsMistake(true);
    setButtonAnimation('incorrect');
    
    setTimeout(() => {
      setIsMistake(false);
      setButtonAnimation('');
    }, 500);
  };


  const showConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 80,
      origin: { x: .6, y: 0.8 },
      gravity: 0.8,
      scalar: 0.8,
      ticks: 200
    });
  };

  const startGame = () => {
    setGameStarted(true);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === 'Space' && isPlaying && gameStarted) {
        event.preventDefault();
        handleBeat();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isPlaying, handleBeat, gameStarted]);


  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        if (playerRef.current) {
          const time = playerRef.current.getCurrentTime();
          setCurrentTime(time);
        }
      }, 16); // Changed to ~60fps for smoother updates
    }
    return () => clearInterval(interval);
  }, [isPlaying]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
  };

  const handleArtistSelect = (artist) => {
    setCurrentArtist(artist);
    setGameState('artistPage');
  };

  const ArtistsPage = ({ artists, handleArtistSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
  
    const filteredArtists = artists.filter(artist => 
      artist.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    return (
      <div className="artists-page dashboard">
        {renderSidebar()}
        <div className="main-content">
          <h1 className="main-title">
            <img 
              src="/arrow.svg"
              alt="Back" 
              className="back-arrow" 
              onClick={handleBackToDashboard}
            />
            {t('artistPage.allArtists')}
          </h1>
          <div className="search-bar">
            <input
              type="text"
              placeholder={t('search.placeholderArtistsPage')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
          <div className="artist-grid">
            {filteredArtists.map((artist, index) => (
              <div key={index} className="artist-card" onClick={() => handleArtistSelect(artist.name)}>
                <img 
                  src={getArtistImage(artist.name)} 
                  alt={artist.name} 
                  onError={(e) => {e.target.onerror = null; e.target.src="/placeholder.png?height=100&width=100"}}
                />
                <h3>{artist.name}</h3>
                <p>{artist.genre}</p>
                <p>{artist.songCount} songs</p>
              </div>
            ))}
          </div>
        </div>
        <MobileNav 
          handleBackToDashboard={handleBackToDashboard}
          setGameState={setGameState}
          signOut={signOut}
        />
      </div>
    );
  };

  const handleBackToDashboard = () => {
    setShouldPlayBeeps(false); // Stop beeps
    setGameState('dashboard');
    setCategory('');
    setCurrentArtist(null);
    setSearchTerm('');
    setDifficulty('all');
    setInstructionsOpacity(1);
  };

  const extractArtists = (songs) => {
    const artistMap = new Map();
    songs.forEach(song => {
      if (!artistMap.has(song.artist)) {
        artistMap.set(song.artist, {
          name: song.artist,
          genre: song.category, // Assuming category represents genre
          songCount: 1
        });
      } else {
        artistMap.get(song.artist).songCount++;
      }
    });
    return Array.from(artistMap.values());
  };

  const handleSelectSearchResult = (type, item) => {
    if (type === 'artist') {
      handleArtistSelect(item.name);
    } else if (type === 'song') {
      handleSongSelect(item);
    }
  };

  const MainSearch = ({ songs, artists, onSelectResult }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState({ artists: [], songs: [] });
    const [showSearchResults, setShowSearchResults] = useState(false);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!event.target.closest('.main-search-bar')) {
          setShowSearchResults(false);
        }
      };
  
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

    const handleSearch = (event) => {
      const term = event.target.value.toLowerCase();
      setSearchTerm(term);
      
      if (term.length > 1) {
        const filteredArtists = artists.filter(artist => 
          artist.name.toLowerCase().includes(term)
        );
        const filteredSongs = songs.filter(song => 
          song.title.toLowerCase().includes(term) || song.artist.toLowerCase().includes(term)
        );
        setSearchResults({ artists: filteredArtists.slice(0, 5), songs: filteredSongs.slice(0, 5) });
        setShowSearchResults(true);
      } else {
        setShowSearchResults(false);
      }
    };
  
    const handleSelectResult = (type, item) => {
      onSelectResult(type, item);
      setSearchTerm('');
      setShowSearchResults(false);
    };
  
    return (
      <div className="main-search-bar">
        <input
          type="text"
          placeholder={t('search.placeholder')}
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        {showSearchResults && (
          <div className="search-results">
            {searchResults.artists.length > 0 && (
              <div className="result-section">
                <h3>{t('search.artists')}</h3>
                {searchResults.artists.map((artist, index) => (
                  <div key={index} className="result-item" onClick={() => handleSelectResult('artist', artist)}>
                    {artist.name}
                  </div>
                ))}
              </div>
            )}
            {searchResults.songs.length > 0 && (
              <div className="result-section">
                <h3>{t('search.songs')}</h3>
                {searchResults.songs.map((song, index) => (
                  <div key={index} className="result-item" onClick={() => handleSelectResult('song', song)}>
                    {song.title} - {song.artist}
                  </div>
                ))}
              </div>
            )}
            {searchResults.artists.length === 0 && searchResults.songs.length === 0 && (
              <div className="no-results">{t('search.noResults')}</div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderSidebar = () => (
    <div className="sidebar">
      <div className="sidebar-top">
        <div className='sidebar-header'>
          <img src='../azucar-flow-logo.png' className='sidebar-logo' alt="Azucar Flow Logo" />
          <LanguageSelector />
        </div>
        <div className='sidebar-divider'></div>
        <div className='side-bar-links'>
          <button className="sidebar-button" onClick={handleBackToDashboard}>
            <img 
                src="../Home.png"
                alt="home-icon" 
                className="sidebar-button-icon" 
            />
            {t('sidebar.home')}
          </button>
          <button className="sidebar-button" onClick={() => setShowAccountPopup(true)}>
            <img 
                src="../Account.png"
                alt="account-icon" 
                className="sidebar-button-icon" 
            />
            {t('sidebar.account')}
          </button>
        </div>
      </div>
      <div className="sidebar-bottom">
        <div className="practice-card">
          <h3>{t('dashboard.practiceCard.title')}</h3>
          <p>{t('dashboard.practiceCard.description')}</p>
          <button className="begin-practice-button">{t('dashboard.practiceCard.button')}</button>
        </div>
      </div>
    </div>
  );
  
  const MobileNav = ({ handleBackToDashboard, setGameState }) => (
    <nav className="mobile-nav">
      <button onClick={handleBackToDashboard}>
        <img src="../Home.png" alt="Home" />
        <span>{t('mobileNav.home')}</span>
      </button>
      <button onClick={() => setShowAccountPopup(true)}>
        <img src="../Account.png" alt="Account" />
        <span>{t('mobileNav.account')}</span>
      </button>
    </nav>
  );

  const renderDashboard = () => (
    <div className="dashboard">
      {renderSidebar()}
      <div className="main-content">
        <h1 className="main-title">{t('dashboard.title')}</h1>
        <MainSearch 
          songs={songs}
          artists={artists}
          onSelectResult={handleSelectSearchResult}
          placeholder={t('dashboard.search.placeholder')}
        />
        <div className="dance-styles">
          <h2>{t('dashboard.danceStyles.title')}</h2>
          <div className="dance-style-cards">
            <div className="dance-style-card salsa" onClick={() => handleCategorySelect('Salsa')}>
              <div className="gif-background">
                <img src="../salsa-bg.gif" alt="Salsa dance" />
              </div>
              <div className="card-content">
                <h3>{t('dashboard.danceStyles.salsa.title')}</h3>
                <p>{t('dashboard.danceStyles.salsa.description')}</p>
              </div>
            </div>
            <div className="dance-style-card bachata" onClick={() => handleCategorySelect('Bachata')}>
              <div className="gif-background">
                <img src="../bachata-bg.gif" alt="Bachata dance" />
              </div>
              <div className="card-content">
                <h3>{t('dashboard.danceStyles.bachata.title')}</h3>
                <p>{t('dashboard.danceStyles.bachata.description')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="featured-artists">
          <div className='dashboard-title-row'>
            <h2>{t('dashboard.featuredArtists.title')}</h2>
            <button onClick={() => setGameState('allArtists')} className="view-all-artists-button">
              {t('dashboard.featuredArtists.viewAll')}
            </button>
          </div>
          <div className="artist-grid">
            {featuredArtists.map((artist, index) => (
              <div key={index} className="artist-card" onClick={() => handleArtistSelect(artist.name)}>
                <img src={artist.image} alt={artist.name} onError={(e) => {e.target.onerror = null; e.target.src="/placeholder.png?height=100&width=100"}} />
                <h3>{artist.name}</h3>
                <p>{artist.genre}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="practice-card mobile-only">
          <h3>{t('dashboard.practiceCard.title')}</h3>
          <p>{t('dashboard.practiceCard.description')}</p>
          <button className="begin-practice-button">{t('dashboard.practiceCard.button')}</button>
        </div>
      </div>
      <MobileNav 
        handleBackToDashboard={handleBackToDashboard}
        setGameState={setGameState}
        signOut={signOut}
      />
    </div>
  );

  const renderSongList = () => {
    const getDifficultyColor = (difficulty) => {
      switch (difficulty) {
        case 'Easy': return 'text-green-500';
        case 'Medium': return 'text-yellow-500';
        case 'Hard': return 'text-red-500';
        default: return '';
      }
    };
  
    return (
      <div className="song-list">
        {renderSidebar()}
        <div className="main-content">
          <h1>
            <img 
              src="/arrow.svg"
              alt="Back" 
              className="back-arrow" 
              onClick={handleBackToDashboard}
            />
            {category}
          </h1>
          <div className="search-bar">
            <input
              type="text"
              placeholder={t('search.categorySongs', { category: category })}
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
          </div>
          <div className="filters">
            <button 
              className={`filter-button ${activeFilter === 'all' ? 'active' : ''}`}
              onClick={() => handleFilterChange('all')}
            >
              {t('filters.all')}
            </button>
            <button 
              className={`filter-button ${activeFilter === 'Easy' ? 'active' : ''}`}
              onClick={() => handleFilterChange('Easy')}
            >
              {t('filters.slowSpeed')}
            </button>
            <button 
              className={`filter-button ${activeFilter === 'Medium' ? 'active' : ''}`}
              onClick={() => handleFilterChange('Medium')}
            >
              {t('filters.mediumSpeed')}
            </button>
            <button 
              className={`filter-button ${activeFilter === 'Hard' ? 'active' : ''}`}
              onClick={() => handleFilterChange('Hard')}
            >
              {t('filters.fastSpeed')}
            </button>
          </div>
          <table className="song-table">
            <thead>
              <tr>
                <th>#</th>
                <th onClick={() => handleSortChange('title')}>{t('table.title')} {sortBy === 'title' && (sortOrder === 'asc' ? '▲' : '▼')}</th>
                <th>{t('table.artist')}</th>
                <th>{t('table.difficulty')}</th>
              </tr>
            </thead>
            <tbody>
              {getFilteredAndSortedSongs().map((song, index) => (
                <tr key={song.id} onClick={() => handleSongSelect(song)}>
                  <td>{index + 1}</td>
                  <td>{song.title}</td>
                  <td>{song.artist}</td>
                  <td className={getDifficultyColor(song.difficulty)}>{t(`difficulty.${song.difficulty.toLowerCase()}`)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <MobileNav 
          handleBackToDashboard={handleBackToDashboard}
          setGameState={setGameState}
          signOut={signOut}
        />
      </div>
    );
  };

  const getStreakColor = (streak) => {
    if (streak < 5) return '#FB233B';  // Red
    if (streak < 10) return '#FFA500'; // Orange
    if (streak < 15) return '#FFFF00'; // Yellow
    return '#00FF00';  // Green
  };
  
  const renderArtistPage = () => (
    <div className="artist-page">
      {renderSidebar()}
      <div className="main-content">
        <h1>
          <img 
            src="/arrow.svg"
            alt="Back" 
            className="back-arrow" 
            onClick={handleBackToDashboard}
          />
          {currentArtist}
        </h1>
        <div className="search-bar">
          <input
            type="text"
            placeholder={t('search.artistSongs', { artist: currentArtist })}
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
        </div>
        <div className="filters">
          <button 
            className={`filter-button ${activeFilter === 'all' ? 'active' : ''}`}
            onClick={() => handleFilterChange('all')}
          >
            {t('filters.all')}
          </button>
          <button 
            className={`filter-button ${activeFilter === 'Easy' ? 'active' : ''}`}
            onClick={() => handleFilterChange('Easy')}
          >
            {t('filters.slowSpeed')}
          </button>
          <button 
            className={`filter-button ${activeFilter === 'Medium' ? 'active' : ''}`}
            onClick={() => handleFilterChange('Medium')}
          >
            {t('filters.mediumSpeed')}
          </button>
          <button 
            className={`filter-button ${activeFilter === 'Hard' ? 'active' : ''}`}
            onClick={() => handleFilterChange('Hard')}
          >
            {t('filters.fastSpeed')}
          </button>
        </div>
        <table className="song-table">
          <thead>
            <tr>
              <th>#</th>
              <th onClick={() => handleSortChange('title')}>{t('table.title')} {sortBy === 'title' && (sortOrder === 'asc' ? '▲' : '▼')}</th>
              <th>{t('table.difficulty')}</th>
              <th>{t('table.type')}</th>
            </tr>
          </thead>
          <tbody>
            {getFilteredAndSortedSongs().map((song, index) => (
              <tr key={song.id} onClick={() => handleSongSelect(song)}>
                <td>{index + 1}</td>
                <td>{song.title}</td>
                <td>{t(`difficulty.${song.difficulty.toLowerCase()}`)}</td>
                <td>{song.category}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <MobileNav 
          handleBackToDashboard={handleBackToDashboard}
          setGameState={setGameState}
          signOut={signOut}
        />
    </div>
  );

  const renderGame = () => {
    const currentBeatIndex = currentSong?.beatMap.findIndex(beat => beat.time > currentTime);
    const currentBeat = currentBeatIndex > 0 ? currentSong?.beatMap[currentBeatIndex - 1].beat : null;
    
    
    // Adjust the beat to our 1,2,3,5,6,7 mapping
    const adjustedBeat = currentBeat ? ((currentBeat - 1) % 8) + 1 : null;
    const displayBeat = adjustedBeat ? [1,2,3,5,6,7][adjustedBeat % 6] : null;
  
    return (
      <div className="game-container">
        {renderSidebar()}
        <div className="main-content" ref={mainContentRef}>
          <h1 className='game-headline'>
            <img 
              src="/arrow.svg"
              alt="Back" 
              className="back-arrow" 
              onClick={handleBackToDashboard}
            />
            {currentSong?.title} - {currentSong?.artist}
          </h1>
          <div 
            className="game-instructions" 
            style={{ opacity: instructionsOpacity, transition: 'opacity 0.5s ease-in-out' }}
          >
            {t('game.playVideoToStart')}
          </div>
          <div className="youtube-container">
            <YouTube
              videoId={currentSong.youtubeId}
              opts={{ height: '390', width: '640', playerVars: { controls: 1, playsinline: 1 } }}
              onReady={(event) => { playerRef.current = event.target; }}
              onStateChange={handleVideoStateChange}
              className="youtube-player"
            />
          </div>
          {gameStarted && (
            <div className="game-content" ref={gameContentRef}>
              <div className="tabs">
                <button 
                  className={`tab-button ${activeTab === 'visualize' ? 'active' : ''}`}
                  onClick={() => setActiveTab('visualize')}
                >
                  {t('game.visualize')}
                </button>
                <button 
                  className={`tab-button ${activeTab === 'test' ? 'active' : ''}`}
                  onClick={() => handleTabChange('test')}
                >
                  {t('game.testYourself')}
                </button>
              </div>
              {activeTab === 'visualize' && (
                <div className="visualize-tab">
                  <div className="game-controls">
                    <label htmlFor="countMode" className="count-mode-label">{t('game.selectCountMode')} </label>
                    <select 
                      id="countMode"
                      value={countMode} 
                      onChange={(e) => setCountMode(e.target.value)}
                      className="count-mode-select"
                    >
                      <option value="1,2,3,4,5,6,7,8">{t('game.count12345678')}</option>
                      <option value="1">{t('game.count1')}</option>
                      <option value="1,5">{t('game.count15')}</option>
                      <option value="1,3,5,7">{t('game.count1357')}</option>
                      <option value="1,2,3,5,6,7">{t('game.count123567')}</option>
                      <option value="2,6">{t('game.count26')}</option>
                      <option value="2,3,6,7">{t('game.count2367')}</option>
                      <option value="4,8">{t('game.count48')}</option>
                      <option value="2,4,6,8">{t('game.count2468')}</option>
                      <option value="2,3,4,6,7,8">{t('game.count234678')}</option>
                    </select>
                    <div className="volume-control">
                      <label htmlFor="beep-volume">{t('game.beepVolume')}:</label>
                      <input
                        type="range"
                        id="beep-volume"
                        min="0"
                        max="1"
                        step="0.1"
                        value={beepVolume}
                        onChange={(e) => setBeepVolume(parseFloat(e.target.value))}
                      />
                    </div>
                  </div>
                  <div className="beat-display">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map(beat => (
                      <span key={beat} className={`beat-indicator ${
                        beatPatterns[countMode].includes(beat) ? 
                          (beat === currentBeat ? 'current' : 'active') : 'inactive'
                      }`}>
                        {beat}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              {activeTab === 'test' && (
                <div className="test-tab">
                  <div className="streak-meter">
                    <div 
                      className={`streak-meter-fill ${isMistake ? 'mistake' : ''}`}
                      style={{
                        width: `${(streak / Math.max(highScore, 1)) * 100}%`
                      }}
                    ></div>
                    <div className="streak-meter-text">{streak}</div>
                  </div>
                  <button 
                    onClick={handleBeat} 
                    className={`beat-button ${buttonAnimation}`}
                  >
                    {t('game.countBeat')}
                  </button>
                  <div className="feedback-message">{feedbackMessage}</div>
                  <div className="stats">
                    <div className="stat-row">
                      <div className="stat-item correct">
                        <div className="stat-label">{t('game.totalCorrect')}</div>
                        <div className="stat-value">{totalCorrect}</div>
                      </div>
                      <div className="stat-item mistakes">
                        <div className="stat-label">{t('game.totalMistakes')}</div>
                        <div className="stat-value">{totalMistakes}</div>
                      </div>
                    </div>
                    <div className="stat-row">
                      <div className="stat-item">
                        <div className="stat-label">{t('game.accuracy')}</div>
                        <div className="stat-value">{accuracy}%</div>
                      </div>
                      <div className="stat-item">
                        <div className="stat-label">{t('game.highestStreak')}</div>
                        <div className="stat-value">{highScore}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="rhythm-game">
      {gameState === 'dashboard' && renderDashboard()}
      {gameState === 'songList' && renderSongList()}
      {gameState === 'artistPage' && renderArtistPage()}
      {gameState === 'allArtists' && (
        <ArtistsPage 
          artists={artists} 
          handleArtistSelect={handleArtistSelect}
          handleBackToDashboard={handleBackToDashboard}
          setGameState={setGameState}
          signOut={signOut}
        />
      )}
      {gameState === 'game' && renderGame()}
      {showAccountPopup && <AccountPopup onClose={() => setShowAccountPopup(false)} />}
    </div>
  );
};

export default RhythmGame;