import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import './AccountPopup.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Add this import

const AccountPopup = ({ onClose }) => {
  const { user, signOut } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState(user.email);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const { t } = useTranslation(); // Add this line

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      // Here you would typically update the email in your backend
      console.log('Email updated to:', email);
    }
  };

  const handleSubscriptionToggle = () => {
    setIsSubscribed(!isSubscribed);
    // Here you would typically update the subscription status in your backend
    console.log('Subscription status updated to:', !isSubscribed);
  };

  const handleSignOut = () => {
    signOut();
    onClose();  // Close the popup after signing out
  };

  return (
    <div className="account-popup">
      <div className="account-popup-content">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>{t('accountPopup.title')}</h2>
        <p>{t('accountPopup.emailIntro')}</p>
        
        <div className="user-info">
          <h3>{user.name}</h3>
          {isEditing ? (
            <input type="email" value={email} onChange={handleEmailChange} />
          ) : (
            <p>{email}</p>
          )}
          {/*
          <button onClick={handleEditToggle}>
            {isEditing ? t('accountPopup.save') : t('accountPopup.edit')}
          </button>*/}
        </div>
        
        <div className="account-actions">
          <button onClick={handleSignOut}>{t('accountPopup.signOut')}</button>
          <Link to="mailto: flow@azucarflow.com" className="support-link">
            {t('accountPopup.contactSupport')}
          </Link>
        </div>
      </div>
      <div className='account-popup-bg'>
        <img src='../landing-page/blur-gradient.webp' alt="Background" />
      </div>
    </div>
  );
};

export default AccountPopup;