import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import './languageSelector.css';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: 'en', name: 'English', flag: '🇺🇸' },
    { code: 'es', name: 'Español', flag: '🇲🇽' },
    { code: 'pt', name: 'Português', flag: '🇧🇷' }
  ];

  // NEW: Added this memoized function
  const setLanguage = useCallback((langCode) => {
    i18n.changeLanguage(langCode);
    localStorage.setItem('userLanguage', langCode);
  }, [i18n]);

  useEffect(() => {
    const storedLang = localStorage.getItem('userLanguage');
    if (storedLang && storedLang !== i18n.language) {
      setLanguage(storedLang);
    }
  }, [i18n.language, setLanguage]); // CHANGED: Updated dependency array

  const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

  const changeLanguage = (langCode) => {
    if (langCode !== i18n.language) { // NEW: Added this check
      setLanguage(langCode); // CHANGED: Use setLanguage instead of separate calls
    }
    setIsOpen(false);
  };

  return (
    <div className="language-selector">
      <button className="language-button" onClick={() => setIsOpen(!isOpen)}>
        {currentLanguage.flag}
        <span className="dropdown-arrow"><img src='/dropdown.png' alt="dropdown" /></span>
      </button>
      {isOpen && (
        <ul className="language-dropdown">
          {languages.map((lang) => (
            <li key={lang.code} onClick={() => changeLanguage(lang.code)}>
              <span>{lang.flag}</span> {lang.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;