import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from './supabaseClient';
import LoadingScreen from './LoadingScreen';
import { detectBrowserLanguage } from './languageDetector';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('userLanguage') || detectBrowserLanguage();
  });

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        setUser(session?.user ?? null);

        if (session?.user) {
          const { data, error } = await supabase
            .from('authorized_users')
            .select('is_admin')
            .eq('email', session.user.email)
            .single();

          if (error) throw error;
          setIsAdmin(data?.is_admin || false);

          await updateUserMetadata(session.user.id, { language });
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    const authTimeout = setTimeout(() => {
      setLoading(false);
    }, 5000);

    checkAuth();

    return () => clearTimeout(authTimeout);
  }, [language]);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedLang = localStorage.getItem('userLanguage');
      if (storedLang && storedLang !== language) {
        setLanguage(storedLang);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [language]);

  const updateUserMetadata = async (userId, metadata) => {
    try {
      const { error } = await supabase.auth.updateUser({ data: metadata });
      if (error) throw error;
    } catch (error) {
      console.error('Error updating user metadata:', error);
    }
  };

  // CHANGED: Updated signIn function to handle name
  const signIn = async ({ email, name }) => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          data: {
            name,
            language
          }
        }
      });
      if (error) throw error;
      return { success: true };
    } catch (error) {
      console.error('Error signing in:', error);
      return { error };
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser(null);
      setIsAdmin(false);
    } catch (error) {
      console.error('Error signing out:', error);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    signIn,
    signOut,
    user,
    isAdmin,
    language,
  };

  if (loading) return <LoadingScreen />;

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);