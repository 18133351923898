// pages/Login.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/Auth.css';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const { signIn, user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await signIn({ email });
      
      if (response && response.error) {
        throw response.error;
      }

      alert(t('checkEmailAlert', { action: t('logIn') }));
    } catch (error) {
      console.error('Authentication error:', error);
      alert(error.message || t('authError'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <form onSubmit={handleSubmit} className='auth-form'>
        <img src='/azucar-flow-logo.png' alt="Azucar Flow Logo" className='auth-form-logo' />
        <h1>{t('welcome')}</h1>
        <p>{t('signInAccount')}</p>
        <div className='auth-form-inputs'>
          <input
            type="email"
            placeholder={t('Email')}
            value={email}
            className='auth-input'
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className='auth-button' disabled={loading}>
            {loading ? t('sending') : t('getLoginLink')}
          </button>
        </div>
        <div className='auth-options'>
          <Link to="/signup" className='auth-toggle'>
            {t('needAccount')}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;